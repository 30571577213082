<template>
  <div class="main columns is-centered">
    <div class="column is-8-desktop is-10-tablet">
      <h1 class="title is-1">Contact Us</h1>
      <p class="subtitle">Questions? Concerns? Feel free to contact the event team through any of the following means</p>

      <br>
      <p class="title">Facebook</p>
      <div class="columns is-variable is-8">
        <div class="column is-narrow">
          <p class="heading">Facebook Page</p>
          <a href="https://www.facebook.com/AteneoLawCareerFair" target="_blank">facebook.com/AteneoLawCareerFair</a>
        </div>
        <div class="column">
          <p class="heading">Send a message via Messenger</p>
          <a href="https://m.me/AteneoLawCareerFair" target="_blank">m.me/AteneoLawCareerFair</a>
          <p class="is-size-7 has-text-grey">Or use the Messenger widget on the bottom right of your screen</p>
        </div>
      </div>

      <br>
      <h1 class="title">Email</h1>
      <p class="heading">Email Address</p>
      <a href="mailto:alscareerfair@gmail.com">alscareerfair@gmail.com</a>
      <br><br>
      <p class="heading">Send a message to our inbox</p>

      <div class="email-form">
        <b-field label="Name">
          <b-input required v-model="name"></b-input>
        </b-field>
        <b-field label="Email">
          <b-input type="email" v-model="email" required></b-input>
        </b-field>
        <b-field label="Message">
          <b-input type="textarea" v-model="message" required></b-input>
        </b-field>
        <b-button type="is-primary" @click="submit" :loading="isSending">Submit</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  title: "Contact | Hirati: Ateneo Law School Career Fair 2023",
  data() { return {
    email: null,
    name: null,
    message: null,
    isSending: false
  }},
  methods: {
    submit: async function() {
      try {
        if (this.email && this.name && this.message) {
          this.isSending = true
          const requestOption = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              name: this.name,
              email: this.email,
              message: this.message
            })
          }
          await fetch('https://formsubmit.co/ajax/alscareerfair@gmail.com', requestOption)
          this.isSending = false
          alert("Message sent! Our team will get back to you shortly.")
        }
        else {
          alert("Please complete the form")
        }
      } catch (e) {
        this.isSending = false
        console.log(e)
        alert("Something went wrong. Please check if you have inputted your name, email, and message. If the problem persists, please try emailing us or sending a message on Facebook.")
      }
    }
  }
}
</script>

<style scoped>
.main {
  padding: 6.9%;
  max-width: 100vw;
  overflow: hidden;
}

.email-form {
  margin-top: 12px;
  max-width: 720px;
}
</style>